@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

/* Global CSS styles */
body, html {
  margin: 0;
  padding: 0;
  height: 100% !important;
  font-family: 'Roboto', sans-serif;
  /* background: linear-gradient(to bottom, #e0e0e0, #ffffff); */
  /* background-color: #e0e0e0; fallback */
}


  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }
  
  input, button {
    font-size: 16px;
  }
  
  button {
    background-color: #020420;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
  }

  .container {
    margin: auto;
    padding: 20px;
  }
  
  /* Responsive layout for mobile screens */
  @media (max-width: 600px) {
    .container {
      margin: 10px;
      padding: 15px;
    }
  
    button {
      width: 100%;
      padding: 15px;
    }
  }
  