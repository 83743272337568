/* General Styling for Image Upload Form */
.image-upload-form {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 400px;
  margin: 0 auto;
}

.image-upload-step {
  margin-top: 20px;
  width: 100%;
}

/* Preview Image Styling */
.preview-img {
  max-width: 100%;
  height: 10vh;
  border-radius: 8px;
  margin: 10px 0;
  object-fit: contain;
}

/* Custom File Upload Button */
.custom-file-upload, .next-step-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  padding: 12px 20px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  margin-bottom: 10px;
}

.custom-file-upload {
  background-color: #002042;
}

.webcam-preview {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}


/* Next Step or Submit Button Styling */
.next-step-button {
  background-color: #020420;
}

/* Loading Spinner Styling */
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full screen height */
}

.spinner {
  position: relative;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db; /* Blue color for top border */
  width: 120px;
  height: 120px;
  animation: spin 1.5s linear infinite, pulse 1.5s ease-in-out infinite;
}

/* Inner circle to add depth */
.spinner::before {
  content: '';
  position: absolute;
  top: 25%;
  left: 25%;
  width: 50%;
  height: 50%;
  background-color: #3498db;
  border-radius: 50%;
  opacity: 0.7;
  animation: pulseInner 1.5s ease-in-out infinite;
}

/* Keyframes for rotating the spinner */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Keyframes for pulsing effect on the outer ring */
@keyframes pulse {
  0%, 100% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.8;
  }
}

/* Keyframes for pulsing effect on the inner circle */
@keyframes pulseInner {
  0%, 100% {
    transform: scale(1);
    opacity: 0.7;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.4;
  }
}

/* Success style for the spinner */
.spinner.success {
  border-color: #28a745;
  border-top-color: #28a745; /* Green color */
  animation: none; /* Stop spinning */
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner.success::before {
  content: '';
  color: #28a745;
  font-size: 2rem;
  position: absolute;
}



/* Modal Overlay and Content */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}



/* Modal Content Styling */
.modal-content {
  background: #fff;
  padding: 20px 20px 20px 20px; /* Extra padding on top for the close button */
  border-radius: 12px;
  max-width: 400px;
  width: 80%;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  position: relative; /* So close button can be positioned absolutely */
  animation: fadeIn 0.3s ease-out;
}

/* Modal Title Styling */
.modal-content h2 {
  font-size: 20px;
  margin: 0;
  color: #333;
  padding: 10px; /* Additional padding for spacing from close button */
}

/* Close Button Styling */
.close-modal-button {
  background: none;
  border: none;
  color: #888;
  font-size: 20px;


}

.close-modal-button:hover {
  background: none;
}

/* Other Modal Button Styling */
.modal-option-button,
.capture-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: #020420;
  color: #fff;
  padding: 10px 15px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  margin-bottom: 15px; /* Consistent spacing */
}

/* Responsive Adjustments */
@media (max-width: 480px) {
  .modal-content h2 {
    font-size: 18px;
  }
  .close-modal-button {
    font-size: 18px;
  }
  .modal-option-button,
  .capture-button {
    font-size: 14px;
    padding: 8px 12px;
  }
}



/* Responsive Adjustments */
@media (max-width: 480px) {
  .image-upload-form {
    padding: 10px;
  }

  .preview-img {
    width: 80px;
    height: 80px;
  }

  .modal-option-button,
  .capture-button,
  .custom-file-upload,
  .next-step-button {
    font-size: 14px;
    padding: 10px;
  }
}


.result-message {
  font-size: 18px;
  color: #28a745;
  text-align: center;
  margin-top: 20px;
}


