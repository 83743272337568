.event-page {
    background-color: #f5f7fa;
    min-height: 100vh;
    color: #333;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .event-page .MuiCard-root {
    background-color: #ffffff;
    border-radius: 10px;
  }
  
  .event-page .MuiTypography-root {
    color: #020420;
  }
  
  .event-page .qr-code {
    margin-top: 20px;
    text-align: center;
  }
  